<template>
    <div>
        <CommunicationCards
            :data="brokerCommunication"
            :title="'Broker'"
        ></CommunicationCards>
        <CommunicationCards
            :data="clientCommunication"
            :title="'Client'"
        ></CommunicationCards>
    </div>
</template>

<script>
import { http } from '@/services'

import CommunicationCards from './Cards/Communication'

export default {
    name: 'CommunicationBroker',
    props: [],
    mounted() {
        this.brokerCommunicationFetch()
    },
    components: {
        CommunicationCards,
    },

    watch: {},

    data() {
        return {
            communication: null,
        }
    },
    computed: {
        brokerCommunication() {
            if (this.communication)
                return this.communication.filter(
                    (comm) => comm.category === 'broker'
                )
            return []
        },
        clientCommunication() {
            if (this.communication)
                return this.communication.filter(
                    (comm) => comm.category === 'client'
                )
            return []
        },
    },
    methods: {
        brokerCommunicationFetch() {
            http.get('communication_broker_list')
                .then((response) => {
                    this.communication = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>
