<template>
    <div class="vx-row">
        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base">
            <vx-card :title="title + ' Timeline'">
                <ul class="vx-timeline">
                    <li
                        v-for="(comm, index) in communicationsOrdered"
                        :key="index"
                    >
                        <div class="flex">
                            <div>
                                <div
                                    class="timeline-icon bg-success"
                                    :class="iconType(comm)[1]"
                                >
                                    <feather-icon
                                        :icon="iconType(comm)[0]"
                                        svgClasses="text-white stroke-current w-5 h-5"
                                    />
                                </div>
                                <div class="timeline-info">
                                    <p class="font-semibold">
                                        {{ comm.title }}
                                    </p>
                                    <span class="activity-desc">{{
                                        comm.description
                                    }}</span>
                                </div>
                                <small class="text-grey activity-e-time"
                                    >Sent on: {{ comm.sent_when }}</small
                                >
                            </div>

                            <div class="ml-auto">
                                <vs-button
                                    radius
                                    type="flat"
                                    icon-pack="feather"
                                    icon="icon-chevron-right"
                                    v-on:click="gotoSelected(comm)"
                                />
                            </div>
                        </div>
                    </li>
                </ul>
            </vx-card>
        </div>
        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base">
            <vx-card :title="title + ' Adhoc'">
                <ul class="vx-timeline timeline-no-border">
                    <li
                        v-for="(comm, index) in communicationsAdhoc"
                        :key="index"
                    >
                        <div class="flex">
                            <div>
                                <div
                                    class="timeline-icon bg-success"
                                    :class="iconType(comm)[1]"
                                >
                                    <feather-icon
                                        :icon="iconType(comm)[0]"
                                        svgClasses="text-white stroke-current w-5 h-5"
                                    />
                                </div>
                                <div class="timeline-info">
                                    <p class="font-semibold">
                                        {{ comm.title }}
                                    </p>
                                    <span class="activity-desc">{{
                                        comm.description
                                    }}</span>
                                </div>
                                <small class="text-grey activity-e-time"
                                    >Sent on: {{ comm.sent_when }}</small
                                >
                            </div>

                            <div class="ml-auto">
                                <vs-button
                                    radius
                                    type="flat"
                                    icon-pack="feather"
                                    icon="icon-chevron-right"
                                    v-on:click="gotoSelected(comm)"
                                />
                            </div>
                        </div>
                    </li>
                </ul>
            </vx-card>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CommunicationCards',
    props: ['data', 'title'],
    data() {
        return {}
    },
    computed: {
        communicationsOrdered() {
            if (this.data)
                return this.data
                    .filter((comm) => comm.order)
                    .sort(
                        (a, b) =>
                            (a.order === null) - (b.order === null) ||
                            a.order - b.order
                    )
            return []
        },
        communicationsAdhoc() {
            if (this.data) return this.data.filter((comm) => !comm.order)
            return []
        },
    },
    methods: {
        gotoSelected(comm) {
            console.log(comm)
            if (comm.type === 'email')
                this.$router.push({ name: 'Email', params: { pk: comm.id } })
            if (comm.type === 'sms')
                this.$router.push({ name: 'Text', params: { pk: comm.id } })
        },
        iconType(comm) {
            if (comm.type === 'email') return ['MailIcon', 'bg-primary']
            if (comm.type === 'sms') return ['MessageCircleIcon', 'bg-warning']
        },
    },
}
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/components/vxTimeline.scss';

#avatar-col {
    width: 10rem;
}
.timeline-no-border {
    border-left: none !important;
}
</style>
